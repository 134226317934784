<template>
  <div id="app">
    <HeaderBlock 
        v-if="!$route.meta.layout 
        && $route.name != 'MedicCabinet' 
        && $route.name != 'Drivers' 
        && $route.name != 'DriverInfo'
        && $route.name != 'MedicProfile'
        && $route.name != 'Docs'"
    />

    <HeaderMedic 
        v-if="$route.name == 'MedicCabinet' 
        || $route.name == 'Drivers' 
        || $route.name == 'DriverInfo'
        || $route.name == 'MedicProfile'"
    />
    <router-view />
    <img class="main-FAQ" src="@/assets/png/FAQ.png" alt="" />
  </div>
</template>

<script>
import HeaderBlock from "@/components/header/header";
import HeaderMedic from "@/components/header/headerMedic";

export default {
  components: {
    HeaderBlock,
    HeaderMedic
  },
  created() {
    if (!this.$route.meta.layout) this.$store.dispatch("getProfileProducts");
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/custom-vars.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";
.main-FAQ {
  background: #83b1f4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 30px rgba(16, 51, 115, 0.2);
  border-radius: 10px;
  position: fixed;
  bottom: 15px;
  right: 15px;
}
</style>
