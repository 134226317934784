<template>
  <div class="header flex">
    <router-link to="/medic">
      <img class="header__logo" src="@/assets/png/logo.png" alt="" />
    </router-link>
    <ul class="header__menu">
      <router-link
        :class="{ 'menu-active': $route.name === 'MedicCabinet' }"
        to="/medic"
        class="header__menu-item"
      >
        Рейсы
      </router-link>
      <router-link
        :class="{ 'menu-active': $route.name === 'Drivers' || $route.name === 'DriverInfo' }"
        to="/drivers"
        class="header__menu-item"
      >
        Водители
      </router-link>
    </ul>
    <div class="header__right-user right-item flex-center"  @click="showUser = !showUser" >
        <b-icon class="color" style="color: #83b1f4" icon="person-fill" />
      </div>
      <ul v-show="showUser" class="header-add__items header-user">
        <button class="header-add__item flex" @click="profile()">
          <p class="header-add__name">Мой аккаунт</p>
        </button>
        <button class="header-add__item flex" @click="bell()">
          <p class="header-add__name">Выход</p>
        </button>
      </ul>
  </div>
</template>

<script>
import Cookies from "js-cookie";
   export default {
      data() {
        return {
          showUser: false
        }
      },
      methods: {
        bell() {
          Cookies.remove("employee_token");
          Cookies.remove("navixy_hash");
          this.$toast.warning("Вы вышли из аккаунта");
          this.$router.push("/auth/authorization");
      },
      profile(){
        this.showUser = !this.showUser;
        this.$router.push('/medic/profile')
      }
      },
   }
</script>

<style scoped>
.header {
  padding: 15px 30px;
  background: #fff;
  height: 70px;
}

.header__right-bell {
  margin-right: 20px;
}
.medic-header{
    height: 70px;
    width: 100%;
    background: white;
    border: solid 2px lightgray;
    text-align: center;
}
.header__logo {
  height: 40px;
  cursor: pointer;
}


.header__menu {
  padding-top: 20px;
}

.header__menu-item {
  border-bottom: 1px solid #00000000;
  cursor: pointer;
  margin-right: 20px;
}

.header__right-user {
  float: right;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #e0e9fa;
  cursor: pointer;
}

.header__database-icon {
  margin-left: 8px;
}

.header__btn {
  padding: 10px 10px 10px 5px;
}

.header__btn:focus {
  outline: none;
}

.header__btn-icon {
  font-size: 185%;
  margin-right: 5px;
}

.header-add__items {
  width: 220px;
  padding: 10px;
  border-radius: 0px 0px 20px 20px;
  background: #ffffff;
  box-shadow: 0px -2px 20px rgba(16, 51, 115, 0.08);
  position: absolute;
  top: 60px;
  right: 10px;
  z-index: 2;
}

.header-add__item {
  justify-content: start;
  height: 45px;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  background: transparent;
  cursor: pointer;
  text-align: left;
}

.header-add__item:hover {
  background: #f2f2f8;
}

.header-add__item:not(:last-child) {
  margin-bottom: 5px;
}

.header-add__img {
  max-width: 24px;
  margin-right: 10px;
}

.menu-active {
  color: var(--secondary-color);
}
.menu-active:hover {
  border-bottom: 1px solid var(--secondary-color);
  color: var(--secondary-color);
}
.header__right-icon {
  fill: #acacac;
  cursor: pointer;
}
</style>