import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Toast from "vue-toastification";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import api, { navixy } from "./utils/api";
import VueTelInput from "vue-tel-input";
import VueSweetalert2 from "vue-sweetalert2";
import ToastBlock from "@/components/toast/index.vue";
import VueTheMask from "vue-the-mask";
import VCalendar from "v-calendar";
import Multiselect from "vue-multiselect";
// import ymaps from 'ymaps'

import "sweetalert2/dist/sweetalert2.min.css";
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import "normalize.css";
import "nprogress/nprogress.css";
import "vue-toastification/dist/index.css";
import "vue-tel-input/dist/vue-tel-input.css";
import "./assets/css/main.css";
import "./assets/css/style.css";

const options = {
  position: "bottom-left",
  icon: false,
  transition: "Vue-Toastification__bounce",
  maxToasts: 3,
  newestOnTop: true,
  // position: "top-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.7,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  // icon: true,
  rtl: false,
};

// const settings = ({
//   apiKey: '5e34fc66-a948-4e55-837c-1253cd994b2f',
//   lang: 'ru_RU',
// })

// Vue.use(ymaps, settings)
Vue.component("ToastBlock", ToastBlock);
Vue.component("Multiselect", Multiselect);
Vue.use(VueSweetalert2);
Vue.use(VueTelInput);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Toast, options);
Vue.use(VueTheMask);
Vue.use(VCalendar, {
  componentPrefix: "vc",
});

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$apiNavixy = navixy;
Vue.prototype.$url = process.env.VUE_APP_URL;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
