<template>
  <Transition name="slide-fade" appear>
    <!-- <div v-if="isLoading || loading" class="my-spinner"> -->
    <div
      v-show="isLoading || loading"
      class="my-spinner"
      :class="{
        'has-bg': hasBg,
      }"
    >
      <div class="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: Number,
      default: 20,
    },
    size: {
      type: Number,
      default: 1,
    },
    opacity: {
      type: Number,
      default: 0.9,
    },
    hasBg: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(["loading"]),
    computedSize() {
      return this.size * 0.75;
    },
    spinnerPadding() {
      return this.computedSize * 8 + "px";
    },
    spinnerSize() {
      return this.computedSize * 66 + "px";
    },
    bgColor() {
      return `rgba(255,255,255,${this.opacity})`;
    },
    boxShadow() {
      return `0 2px 10px 2px rgba(0,0,0,${this.opacity / 7})`;
    },
    ldsSize() {
      return this.computedSize * 60 + "px";
    },
    ldsRingSize() {
      return this.computedSize * 48 + "px";
    },
    ldsRingMargin() {
      return this.computedSize * 0 + "px";
    },
    ldsRingBorder() {
      return this.computedSize * 6 + "px solid #000";
    },
  },
  created() {
    // console.debug("hasBg", this.hasBg);
  },
};
</script>

<style scoped>
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-50%) translateY(-7.5vh) !important;
  opacity: 0;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.my-spinner {
  position: fixed;
  z-index: v-bind(zIndex);
  left: 50vw;
  top: 15vh;
  width: v-bind(spinnerSize);
  height: v-bind(spinnerSize);
  transform: translateX(-50%);
  /* box-sizing: content-box; */
  padding: v-bind(spinnerPadding);
  border-radius: 999rem;
}
.has-bg {
  background-color: v-bind(bgColor);
  box-shadow: v-bind(boxShadow);
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: v-bind(ldsSize);
  height: v-bind(ldsSize);
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: v-bind(ldsRingSize);
  height: v-bind(ldsRingSize);
  margin: v-bind(ldsRingMargin);
  border: v-bind(ldsRingBorder);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #215fce transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
