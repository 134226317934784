<template>
  <div class="header flex">
    <router-link to="/">
      <img class="header__logo" src="@/assets/png/logo.png" alt="" />
    </router-link>
    <ul class="header__menu flex">
      <router-link
        :class="{ 'menu-active': $route.name === 'Home' }"
        to="/"
        class="header__menu-item"
      >
        Главная
      </router-link>
      <router-link
        :class="{ 'menu-active': $route.name === 'Orders' }"
        to="/orders"
        class="header__menu-item"
      >
        Заказы
      </router-link>
      <router-link
        :class="{ 'menu-active': $route.name === 'Route' }"
        to="/route"
        class="header__menu-item"
      >
        Маршруты
      </router-link>
      <router-link
        :class="{ 'menu-active': $route.name === 'GeoZone' }"
        to="/geozone"
        class="header__menu-item"
      >
        Геозоны
      </router-link>
      <router-link
        :class="{ 'menu-active': $route.name === 'Analytics' }"
        to="/analytics"
        class="header__menu-item"
      >
        Аналитика
      </router-link>
      <div
        class="header__menu-item"
        :class="{ 'menu-active': $route.name === 'Report' }"
        @click="showReport = !showReport"
      >
        Отчет
        <img
          v-if="$route.name !== 'Report'"
          class="header__database-icon"
          src="@/assets/png/select-icon.png"
          alt=""
        />
        <img
          v-if="$route.name === 'Report'"
          class="header__database-icon"
          src="@/assets/png/select-active.png"
          alt=""
        />
        <ul v-if="showReport" class="header__database-menu">
          <li class="header__database-item">
            <router-link to="/report/orders" class="header__database-item-link">
              <img class="header-add__img" src="@/assets/png/car.png" alt="" />
              Отчет по заказам
            </router-link>
          </li>
          <li class="header__database-item">
            <router-link to="/report/fuel" class="header__database-item-link">
              <img class="header-add__img" src="@/assets/png/car.png" alt="" />
              Отчет по топливу
            </router-link>
          </li>
          <li class="header__database-item">
            <router-link to="/report/transport-oil" class="header__database-item-link">
              <img class="header-add__img" src="@/assets/png/car.png" alt="" />
              Отчет по ГСМ
            </router-link>
          </li>
          <li class="header__database-item">
            <router-link to="/report/one-c" class="header__database-item-link">
              <img class="header-add__img" src="@/assets/png/add-user.png" alt="" />
              Отправки в 1С
            </router-link>
          </li>
          <li class="header__database-item">
            <router-link to="/report/unsetting-points" class="header__database-item-link">
              <img class="header-add__img" src="@/assets/png/add-user.png" alt="" />
              Отложенные заказы
            </router-link>
          </li>
        </ul>
      </div>
      <div
        class="header__menu-item header__database"
        :class="{ 'menu-active': $route.name === 'Database' }"
        @click="showMenu = !showMenu"
      >
        База данных
        <img
          v-if="$route.name !== 'Database'"
          class="header__database-icon"
          src="@/assets/png/select-icon.png"
          alt=""
        />
        <img
          v-if="$route.name === 'Database'"
          class="header__database-icon"
          src="@/assets/png/select-active.png"
          alt=""
        />
        <ul v-if="showMenu" class="header__database-menu">
          <li class="header__database-item">
            <router-link to="/database/car-park/transports" class="header__database-item-link">
              <img class="header-add__img" src="@/assets/png/car.png" alt="" />
              Автопарк
            </router-link>
          </li>
          <li class="header__database-item">
            <router-link to="/database/drivers" class="header__database-item-link">
              <img class="header-add__img" src="@/assets/png/user-box.png" alt="" />
              Водители
            </router-link>
          </li>
          <li class="header__database-item">
            <router-link to="/database/expeditors" class="header__database-item-link">
              <img class="header-add__img" src="@/assets/png/user-box.png" alt="" />
              Экспедиторы
            </router-link>
          </li>
          <li class="header__database-item">
            <router-link to="/database/purchases" class="header__database-item-link">
              <img class="header-add__img" src="@/assets/png/cart.png" alt="" />
              Закупки
            </router-link>
          </li>
          <li
            v-if="
              profileProducts &&
              profileProducts.position &&
              profileProducts.position.title === 'moderator'
            "
            class="header__database-item"
          >
            <router-link to="/database/managers" class="header__database-item-link">
              <img class="header-add__img" src="@/assets/png/user-box.png" alt="" />
              Пользователи
            </router-link>
          </li>
          <li class="header__database-item" v-if="profileProducts.position.id !== 6 && profileProducts.position.id !== 7">
            <router-link to="/database/counterparties" class="header__database-item-link">
              <img class="header-add__img" src="@/assets/png/user-group.png" alt="" />
              Контрагенты
            </router-link>
          </li>
          <li class="header__database-item" v-if="profileProducts.position.id !== 6 && profileProducts.position.id !== 7">
            <router-link to="/database/cities" class="header__database-item-link">
              <img class="header-add__img" src="@/assets/png/city.png" alt="" />
              Города
            </router-link>
          </li>
          <li class="header__database-item" v-if="profileProducts.position.id !== 6 && profileProducts.position.id !== 7">
            <router-link to="/database/regions" class="header__database-item-link">
              <img class="header-add__img" src="@/assets/png/direction.png" alt="" />
              Направления
            </router-link>
          </li>
          <li class="header__database-item" v-if="profileProducts.position.id !== 3">
            <router-link to="/database/products" class="header__database-item-link" >
              <img class="header-add__img" src="@/assets/png/add-truck.png" alt=""/>
              Запчасти
            </router-link>
          </li>
          <li class="header__database-item">
            <router-link to="/database/cost" class="header__database-item-link" >
              <img class="header-add__img" src="@/assets/png/add-truck.png" alt=""/>
              Расчет стоимости тарифа
            </router-link>
          </li>
        </ul>
      </div>
      <div
        v-if="showMenu || showReport"
        class="main-close"
        @click="(showMenu = false), (showReport = false)"
      />
    </ul>
    <div class="header__right flex">
      <button class="header__btn right-item default-btn flex" @click="showAdd = !showAdd">
        <b-icon class="header__btn-icon" icon="plus" font-scale="2" />
        Добавить
      </button>
      <!--      <b-icon style="cursor: pointer" variant="danger" icon="door-open-fill" font-scale="1.56" @click="bell()"></b-icon>-->
      <div class="header__right-user right-item flex-center" @click="showUser = !showUser">
        <b-icon class="color" style="color: #83b1f4" icon="person-fill" />
      </div>
      <ul v-show="showUser" class="header-add__items header-user">
        <button class="header-add__item flex" @click="$router.push('/profile')">
          <p class="header-add__name">Мой аккаунт</p>
        </button>
        <button class="header-add__item flex" @click="bell()">
          <p class="header-add__name">Выход</p>
        </button>
      </ul>
    </div>
    <ul v-if="showAdd" class="header-add__items" >
      <button class="header-add__item flex" @click="addChange('/add/new-order')" v-if="profileProducts.position.id !== 6 && profileProducts.position.id !== 7">
        <img class="header-add__img" src="@/assets/png/add-receipt.png" alt="" />
        <p class="header-add__name">Новый заказ</p>
      </button>
      <button class="header-add__item flex" @click="addChange('/add/new-carriage')" v-if="profileProducts.position.id !== 6 && profileProducts.position.id !== 7">
        <img class="header-add__img" src="@/assets/png/add-receipt.png" alt="" />
        <p class="header-add__name">Новая перевозка</p>
      </button>
      <button class="header-add__item flex" @click="addChange('/add/new-transport/')">
        <img class="header-add__img" src="@/assets/png/add-truck.png" alt="" />
        <p class="header-add__name">Новый транспорт</p>
      </button>
      <button class="header-add__item flex" @click="addChange('/add/new-driver/')">
        <img class="header-add__img" src="@/assets/png/add-user.png" alt="" />
        <p class="header-add__name">Новый водитель</p>
      </button>
      <button class="header-add__item flex" @click="addChange('/add/new-expeditor/')">
        <img class="header-add__img" src="@/assets/png/add-user.png" alt="" />
        <p class="header-add__name">Новый экспедитор</p>
      </button>
      <button
        v-if="
          profileProducts &&
          profileProducts.position &&
          profileProducts.position.title === 'moderator'
        "
        class="header-add__item flex"
        @click="addChange('/add/new-manager/')"
      >
        <img class="header-add__img" src="@/assets/png/add-group.png" alt="" />
        <p class="header-add__name">Новый пользователь</p>
      </button>
      <button class="header-add__item flex" @click="addChange('/add/new-counterparty/')" v-if="profileProducts.position.id !== 6 && profileProducts.position.id !== 7">
        <img class="header-add__img" src="@/assets/png/add-group.png" alt="" />
        <p class="header-add__name">Новый Контерагент</p>
      </button>
      <button class="header-add__item flex" @click="addChange('/add/new-city/')" v-if="profileProducts.position.id !== 6 && profileProducts.position.id !== 7">
        <img class="header-add__img" src="@/assets/png/city.png" alt="" />
        <p class="header-add__name">Новый город</p>
      </button>
      <button class="header-add__item flex" @click="addChange('/add/new-region/')" v-if="profileProducts.position.id !== 6 && profileProducts.position.id !== 7">
        <img class="header-add__img" src="@/assets/png/direction.png" alt="" />
        <p class="header-add__name">Новое направление</p>
      </button>
      <button class="header-add__item flex" @click="addChange('/add/new-purchase/')">
        <img class="header-add__img" src="@/assets/png/cart.png" alt="" />
        <p class="header-add__name">Провести закуп</p>
      </button>
      <button class="header-add__item flex" @click="addChange('/add/new-cost/')">
        <img class="header-add__img" src="@/assets/png/cart.png" alt="" />
        <p class="header-add__name">Новый расчет стоимости</p>
      </button>
    </ul>
    <div
      v-if="showAdd || showUser"
      class="main-close"
      @click="(showAdd = false), (showUser = false)"
    />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapState } from "vuex";

export default {
  data() {
    return {
      showMenu: false,
      showUser: false,
      showAdd: false,
      showReport: false,
    };
  },
  computed: {
    ...mapState(["profileProducts"]),
  },
  watch: {
    $route() {
      this.showAdd = false;
    },
  },
  methods: {
    addChange(value) {
      this.showAdd = false;
      if (this.$route.path === value) {
        return;
      }
      this.$router.push(value);
    },
    bell() {
      Cookies.remove("employee_token");
      Cookies.remove("navixy_hash");
      this.$store.dispatch("logout");
      this.$toast.warning("Вы вышли из аккаунта");
      this.$router.push("/auth/authorization");
    },
    // postExport() {
    //   this.$api.get('/web/edit-counteragent', {
    //   })
    //     .then(() => {
    //       this.$toast.success('Успешно!')
    //       this.$store.dispatch("getContragentsList")
    //       this.router.push('database/counterparties')
    //     })
    //     .catch(e => {
    //       this.$toast.error(e.response.data);
    //     })
    // }
  },
};
</script>

<style scoped>
.header {
  padding: 15px 30px;
  background: #fff;
  height: 70px;
}

.header__logo {
  height: 40px;
  cursor: pointer;
}

.header__menu {
  height: 24px;
}

.header__menu-item {
  border-bottom: 1px solid #00000000;
  position: relative;
  cursor: pointer;
  margin-right: 20px;
}
.header__menu-item:hover {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid #000000;
}

.right-item:not(:last-child) {
  margin-right: 20px;
}

.header__right-user {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-left: 18px;
  background-color: #e0e9fa;
  cursor: pointer;
}

.header__right-icon {
  fill: #acacac;
  cursor: pointer;
}

.menu-active {
  color: var(--secondary-color);
}
.menu-active:hover {
  border-bottom: 1px solid var(--secondary-color);
  color: var(--secondary-color);
}

.header__database {
  margin: 0;
}

.header__database-menu {
  min-width: 280px;
  padding: 10px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px -2px 20px rgb(16 51 115 / 8%);
  border-radius: 8px;
  position: absolute;
  top: 30px;
  left: -10px;
  z-index: 2;
}

.header__database-item-link {
  height: 100%;
  width: 100%;
  padding: 10px;
  display: block;
  cursor: pointer;
  border-radius: 8px;
  color: #000;
}

.header__database-item-link:hover {
  text-decoration: none;
  color: black;
  background: #e0e9fa;
}

.header__database-icon {
  margin-left: 8px;
}

.header__btn {
  padding: 10px 10px 10px 5px;
}

.header__btn:focus {
  outline: none;
}

.header__btn-icon {
  font-size: 185%;
  margin-right: 5px;
}

.header-add__items {
  width: 220px;
  padding: 10px;
  border-radius: 0px 0px 20px 20px;
  background: #ffffff;
  box-shadow: 0px -2px 20px rgba(16, 51, 115, 0.08);
  position: absolute;
  top: 70px;
  right: 155px;
  z-index: 2;
}

.header-add__item {
  justify-content: start;
  height: 45px;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  background: transparent;
  cursor: pointer;
  text-align: left;
}

.header-add__item:hover {
  background: #f2f2f8;
}

.header-add__item:not(:last-child) {
  margin-bottom: 5px;
}

.header-add__img {
  max-width: 24px;
  margin-right: 10px;
}

.create-close {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}
.header-user {
  right: 45px;
}
</style>
