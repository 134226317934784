<template>
  <div class="toast-box flex">
    <img src="../../assets/png/toast-success.png" class="toast__icon" />
    <div>
      <h1 class="toast__title">
        {{ title }}
      </h1>
      <span class="toast__text">
        {{ text }}
      </span>
    </div>
  </div>
</template>

<script>
import store from "@/store/index";
export default {
  data() {
    return {
      position: null,
      title: null,
      text: null,
    };
  },
  computed: {},
  mounted() {
    if (store.getters.TOAST_DATA) {
      this.position = store.getters.TOAST_DATA.position;
      this.title = store.getters.TOAST_DATA.title;
      this.text = store.getters.TOAST_DATA.text;
    }
  },
};
</script>

<style scoped>
.toast-box {
  justify-content: flex-start;
  font-family: "Product Sans", sans-serif;
  font-style: normal;
}
.toast__icon {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}
.toast__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}
.toast__text {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin: 5px 0;
}
</style>
