import axios from "axios";
import Cookies from "js-cookie";

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // navixy: process.env.VUE_APP_NAVIXY_API_URL,
  timeout: 60 * 24 * 60,
});

service.interceptors.request.use(
  (config) => {
    if (Cookies.get("employee_token")) {
      // config.headers["Authorization"] = "Bearer " + Cookies.get("employee_token");
      config.headers["Authorization"] = "Bearer " + Cookies.get("employee_token");
    }
    // else {
    //   config.headers["Authorization"] = "Bearer 9|XHbMTjATWuq5FNFqMye8UCrADGTsi7bDubnRBfvc";
    // }
    return config;
  },
  (error) => {
    alert(error); // for debug
    return Promise.reject(error);
  }
);

const navixy = axios.create({
  baseURL: process.env.VUE_APP_NAVIXY_API_URL,
  timeout: 60 * 24 * 60,
});

export { navixy };

export default service;
