var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(!_vm.$route.meta.layout 
      && _vm.$route.name != 'MedicCabinet' 
      && _vm.$route.name != 'Drivers' 
      && _vm.$route.name != 'DriverInfo'
      && _vm.$route.name != 'MedicProfile'
      && _vm.$route.name != 'Docs')?_c('HeaderBlock'):_vm._e(),(_vm.$route.name == 'MedicCabinet' 
      || _vm.$route.name == 'Drivers' 
      || _vm.$route.name == 'DriverInfo'
      || _vm.$route.name == 'MedicProfile')?_c('HeaderMedic'):_vm._e(),_c('router-view'),_c('img',{staticClass:"main-FAQ",attrs:{"src":require("@/assets/png/FAQ.png"),"alt":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }