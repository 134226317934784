import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home";
import Cookies from "js-cookie";
import store from "../store/index";

Vue.use(VueRouter);

const haveToken = (to, from, next) => {
  if (Cookies.get("employee_token")) next();
  else if (to.path !== "/auth/authorization") next("/auth/authorization");
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: haveToken,
  },
  {
    path: "/order/:type",
    name: "order",
    component: () => import("../views/order"),
    beforeEnter: haveToken,
  },
  {
    path: "/auth/:type",
    name: "auth",
    meta: { layout: "header" },
    component: () => import("../views/auth"),
  },
  {
    path: "/orders",
    name: "Orders",
    component: () => import("../views/orders"),
    beforeEnter: haveToken,
  },
  {
    path: "/route",
    name: "Route",
    component: () => import("../views/route"),
    beforeEnter: haveToken,
  },
  {
    path: "/route/:id",
    name: "Route_show",
    component: () => import("../views/route/components/order"),
    beforeEnter: haveToken,
  },
  {
    path: "/zone",
    name: "Zone",
    component: () => import("../views/zone"),
    beforeEnter: haveToken,
  },
  {
    path: "/geozone",
    name: "GeoZone",
    component: () => import("../views/geozone"),
    beforeEnter: haveToken,
  },
  {
    path: "/analytics",
    name: "Analytics",
    component: () => import("../views/analytics"),
    beforeEnter: haveToken,
  },
  {
    path: "/analytics/:type",
    name: "Analytics",
    component: () => import("../views/analytics"),
    beforeEnter: haveToken,
  },
  {
    path: "/database/:type",
    name: "Database",
    component: () => import("../views/database"),
    beforeEnter: haveToken,
  },
  {
    path: "/database/:type/:id",
    name: "Database",
    component: () => import("../views/database"),
    beforeEnter: haveToken,
  },
  {
    path: "/report/:type",
    name: "Report",
    component: () => import("../views/report"),
    beforeEnter: haveToken,
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/profile"),
    beforeEnter: haveToken,
  },
  {
    path: "/add/:type",
    name: "Add",
    component: () => import("../views/add"),
    beforeEnter: haveToken,
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/error"),
  },
  {
    path: "/medic",
    name: "MedicCabinet",
    component: () => import("../views/medic"),
    beforeEnter: haveToken,
  },
  {
    path: "/medic/profile",
    name: "MedicProfile",
    component: () => import("../views/medic/components/profile"),
    beforeEnter: haveToken,
  },
  {
    path: "/drivers",
    name: "Drivers",
    component: () => import("../views/drivers"),
    beforeEnter: haveToken,
  },
  {
    path: "/drivers/:id",
    name: "DriverInfo",
    component: () => import("../views/drivers/components/driverInfo"),
    beforeEnter: haveToken,
  },
  {
    path: "/docs/:type",
    name: "Docs",
    component: () => import("../views/docs"),
    // beforeEnter: haveToken,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("setLoading", 0);
  next();
});

export default router;
