<template>
  <div class="home">
    <SubHeaderBlock />
    <Spinner :is-loading="spinnerShow" />
    <div class="home-block container">
      <ActiveOrdersBlock @spinner="spinnerShow = false" />
      <CalendarBlock />
      <FaultsBlock />
      <NotificationsBlock />
    </div>
  </div>
</template>

<script>
import Spinner from "../../components/spinner/index.vue";
export default {
  components: {
    SubHeaderBlock: () => ({
      component: import("./components/subHeader"),
    }),
    ActiveOrdersBlock: () => ({
      component: import("./components/activeOrders"),
    }),
    FaultsBlock: () => ({
      component: import("./components/faults"),
    }),
    NotificationsBlock: () => ({
      component: import("./components/notifications"),
    }),
    CalendarBlock: () => ({
      component: import("./components/calendar"),
    }),
    Spinner,
  },
  data() {
    return {
      spinnerShow: true,
    };
  },
  mounted() {
  },
};
</script>

<style scoped>
.home-block {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.home-component {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
</style>
